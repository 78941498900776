import {
  getCoverLink,
  getEpubLink,
  getThaliaLink,
  getWeltbildLink,
  getAmazonLink,
  getKoboLink,
  getGoogleLink,
  getBarnesLink,
  getAppleLink,
} from "./link-utils";
import { EBook, PageTableItem } from "./types";

export const getEBookTableSchema = (
  t: (key: string) => string,
  selectEbook: (ebook: EBook) => void,
  selectEbookForFile: (ebook: EBook) => void,
  selectEbookForRating: (ebook: EBook) => void
): PageTableItem<EBook>[] => [
  {
    label: t("cover"),
    key: "cover",
    roles: [],
    type: "image",
    style: {
      padding: "8px 4px",
    },
  },
  {
    label: t("isbn"),
    key: "isbn",
    roles: [],
    sortable: true,
    onClick: selectEbook,
    style: {
      minWidth: "100px",
      maxWidth: "100px",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: t("title"),
    key: "title",
    roles: [],
    sortable: true,
    style: {
      minWidth: "230px",
      maxWidth: "230px",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: t("publishingDate"),
    key: "publishingDate",
    roles: [],
    type: "date",
    sortable: true,
    style: {
      minWidth: "80px",
      maxWidth: "80px",
      textAlign: "center",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: t("files"),
    key: "files",
    roles: ["EbookCollaborationGrp"],
    onClick: selectEbookForFile,
    valueTransform: (_) => t("files"),
    style: {
      minWidth: "45px",
      maxWidth: "45px",
      textAlign: "center",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: t("amountLastPeriod"),
    key: "amountLastPeriod",
    roles: ["EbookAuthorSaleInfo", "EbookPublisherMarketingInfo"],
    sortable: true,
    style: {
      minWidth: "80px",
      maxWidth: "80px",
      textAlign: "center",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: t("amountFirst1Month"),
    key: "amountFirst1Month",
    roles: ["EbookAuthorSaleInfo", "EbookPublisherMarketingInfo"],
    sortable: true,
    style: {
      minWidth: "80px",
      maxWidth: "80px",
      textAlign: "center",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: t("amountFirst6Months"),
    key: "amountFirst6Months",
    roles: ["EbookAuthorSaleInfo", "EbookPublisherMarketingInfo"],
    sortable: true,
    style: {
      minWidth: "80px",
      maxWidth: "80px",
      textAlign: "center",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: t("amountFirst12Months"),
    key: "amountFirst12Months",
    roles: ["EbookAuthorSaleInfo", "EbookPublisherMarketingInfo"],
    sortable: true,
    style: {
      minWidth: "80px",
      maxWidth: "80px",
      textAlign: "center",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: t("amountTotal"),
    key: "amountTotal",
    roles: ["EbookAuthorSaleInfo", "EbookPublisherMarketingInfo"],
    sortable: true,
    style: {
      minWidth: "80px",
      maxWidth: "80px",
      textAlign: "center",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: `${t("price")} ${t("eur")}`,
    key: "priceEur",
    roles: ["EbookPublisherMarketingInfo"],
    sortable: true,
    style: {
      minWidth: "45px",
      maxWidth: "45px",
      textAlign: "center",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: `${t("priceInterval")} ${t("eur")}`,
    key: "priceIntervalEur",
    roles: ["EbookPublisherMarketingInfo"],
    style: {
      minWidth: "70px",
      maxWidth: "70px",
      textAlign: "center",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },

  {
    label: t("advertisedBook"),
    key: "advertisedBook",
    roles: ["EbookPublisherMarketingInfo"],
    sortable: true,
    style: {
      minWidth: "190px",
      maxWidth: "190px",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: t("author"),
    key: "author",
    roles: [],
    style: {
      minWidth: "190px",
      maxWidth: "190px",
      boxSizing: "content-box",
      padding: "8px 4px",
    },
  },
  {
    label: t("cover"),
    key: "cover",
    roles: [],
    type: "state",
    sortable: true,
    link: getCoverLink,
    valueTransform: (value) => (value ? "y" : "n"),
    style: {
      minWidth: "85px",
      maxWidth: "85px",
      textAlign: "center",
      padding: "8px 4px",
    },
  },
  {
    label: t("epub"),
    key: "epub",
    roles: [],
    type: "state",
    sortable: true,
    link: getEpubLink,
    style: {
      minWidth: "85px",
      maxWidth: "85px",
      textAlign: "center",
      padding: "8px 4px",
    },
  },
  {
    label: t("thalia"),
    key: "thalia",
    roles: [],
    type: "state",
    sortable: true,
    link: getThaliaLink,
    style: {
      minWidth: "85px",
      maxWidth: "85px",
      textAlign: "center",
      padding: "8px 4px",
    },
  },
  // temporary hidden as this provider is no longer available
  // {
  //   label: t("weltbild"),
  //   key: "weltbild",
  //   roles: [],
  //   type: "state",
  //   sortable: true,
  //   link: getWeltbildLink,
  //   style: {
  //     minWidth: "85px",
  //     maxWidth: "85px",
  //     textAlign: "center",
  //     padding: "8px 4px",
  //   },
  // },
  {
    label: t("amazon"),
    key: "amazon",
    roles: [],
    type: "state",
    sortable: true,
    link: getAmazonLink,
    style: {
      minWidth: "85px",
      maxWidth: "85px",
      textAlign: "center",
      padding: "8px 4px",
    },
  },
  {
    label: t("apple"),
    key: "apple",
    roles: [],
    type: "state",
    sortable: true,
    link: getAppleLink,
    style: {
      minWidth: "85px",
      maxWidth: "85px",
      textAlign: "center",
      padding: "8px 4px",
    },
  },
  {
    label: t("kobo"),
    key: "kobo",
    type: "state",
    sortable: true,
    roles: ["EbookLinkCheckKoboGrp"],
    link: getKoboLink,
    style: {
      minWidth: "85px",
      maxWidth: "85px",
      textAlign: "center",
      padding: "8px 4px",
    },
  },
  {
    label: t("google"),
    key: "google",
    roles: ["EbookLinkCheckGoogleGrp"],
    type: "state",
    sortable: true,
    link: getGoogleLink,
    style: {
      minWidth: "85px",
      maxWidth: "85px",
      textAlign: "center",
      padding: "8px 4px",
    },
  },
  {
    label: t("barnes"),
    key: "barnes",
    roles: ["EbookLinkCheckBNGrp"],
    type: "state",
    sortable: true,
    link: getBarnesLink,
    style: {
      minWidth: "85px",
      maxWidth: "85px",
      textAlign: "center",
      padding: "8px 4px",
    },
  },
];
