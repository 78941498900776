import React from "react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

export type StateType = "y" | "n" | null;

type StateProps = {
  value: StateType;
  link: String;
};

const State = ({ value, link }: StateProps) => {
  if (value === "y") {
    return (
      <a href={`${link}`} target="_blank">
        <CheckIcon color="green.400" />
      </a>
    );
  }
  if (value === "n") {
    return <CloseIcon color="red.500" />;
  }
  return <CloseIcon color="gray.200" />;
};

export default State;
