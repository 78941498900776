import React, { useState } from "react";
import {
  Table,
  Tbody,
  Tr,
  Td,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import Header from "../../components/header";
import { useInfiniteQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { EBook, Page } from "./types";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ReportChartDetail from "../../components/report-chart-detail";
import FileUploadModal from "../../components/file-upload-modal";
import FileDrawer from "../../components/file-drawer";
import RatingsListDrawer from "../../components/ratings-list-drawer";
import PageHeader from "../../components/page-header";
import PageRow from "../../components/page-row";
import axios from "axios";
import { getEBookTableSchema } from "./ebook-table-schema";

const FILE_LIST = "FILE_LIST";
const FILE_ADD = "FILE_ADD";
const RATING = "RATING";

type ModalType = {
  isbn: String;
  type: String;
};

const EBooksPage = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedEbook, setSelectedEbook] = useState<String>();
  const [modal, setModal] = useState<ModalType | undefined>(undefined);
  const { data, error, fetchNextPage, hasNextPage, status } = useInfiniteQuery<
    Page<EBook>
  >(
    ["users", searchParams.get("fieldName"), searchParams.get("direction")],
    async ({ pageParam = 0 }) => {
      const response = await axios
        .get(`/api/ebook?${searchParams}&page=${pageParam}`)
        .then((res) => res.data);
      const data: Page<EBook> = {
        results: response,
        next: response?.length > 0 ? pageParam + 1 : undefined,
      };
      return data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.next,
    }
  );
  const handleSort = (fieldName: string) => {
    let direction = "DESC";

    if (
      fieldName === searchParams.get("fieldName") &&
      searchParams.get("direction") === "DESC"
    ) {
      direction = "ASC";
    }
    setSearchParams(new URLSearchParams({ fieldName, direction }));
  };

  const dataLength = data?.pages.reduce((counter, page) => {
    return counter + page?.results?.length;
  }, 0);

  return (
    <>
      <div
        style={{ height: "100vh", overflowY: "scroll" }}
        id="scrollbar-target"
      >
        <Header />
        <InfiniteScroll
          dataLength={dataLength || 0}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={
            <p
              style={{
                color: "deepskyblue",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              <Spinner />
            </p>
          }
          data-testid="infinite-scroll"
          scrollableTarget="scrollbar-target"
          style={{ padding: "5px", overflow: "unset" }}
          endMessage={<></>}
        >
          <Table size="sm">
            <PageHeader
              schema={getEBookTableSchema(
                t,
                (ebook: EBook) => setSelectedEbook(ebook.isbn),
                (ebook: EBook) =>
                  setModal({ isbn: ebook.isbn, type: FILE_LIST }),
                (ebook: EBook) => setModal({ isbn: ebook.isbn, type: RATING })
              )}
              handleSort={handleSort}
              sort={{
                fieldName: searchParams.get("fieldName"),
                direction: searchParams.get("direction"),
              }}
            />
            <Tbody>
              {status === "loading" && (
                <Tr>
                  <Td colSpan={17} alignItems="center">
                    <Spinner />
                  </Td>
                </Tr>
              )}
              {!!error && (
                <Tr>
                  <Td colSpan={17}>{t("dataLoadError")}</Td>
                </Tr>
              )}

              {!error &&
                status !== "loading" &&
                data?.pages?.map((group, i) => (
                  <React.Fragment key={i}>
                    {group?.results?.map((ebook: EBook) => (
                      <PageRow<EBook>
                        key={ebook.isbn}
                        schema={getEBookTableSchema(
                          t,
                          (ebook: EBook) => setSelectedEbook(ebook.isbn),
                          (ebook: EBook) =>
                            setModal({ isbn: ebook.isbn, type: FILE_LIST }),
                          (ebook: EBook) =>
                            setModal({ isbn: ebook.isbn, type: RATING })
                        )}
                        object={ebook}
                      />
                    ))}
                  </React.Fragment>
                ))}
            </Tbody>
          </Table>
        </InfiniteScroll>
      </div>
      <Modal
        isOpen={!!selectedEbook}
        onClose={() => setSelectedEbook(undefined)}
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("report")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReportChartDetail isbn={selectedEbook || ""} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <FileDrawer
        isbn={modal?.isbn}
        isOpen={modal?.type === FILE_LIST}
        close={() => setModal(undefined)}
        addFile={() => setModal({ isbn: modal?.isbn || "", type: FILE_ADD })}
      />
      <FileUploadModal
        isbn={modal?.isbn}
        isOpen={modal?.type === FILE_ADD}
        close={() => setModal({ isbn: modal?.isbn || "", type: FILE_LIST })}
      />
      <RatingsListDrawer
        isbn={modal?.isbn}
        isOpen={modal?.type === RATING}
        close={() => setModal(undefined)}
      />
    </>
  );
};

export default EBooksPage;
