import React, { useMemo, useState } from "react";
import {
  Container,
  FormControl,
  FormLabel,
  Input,
  Card,
  CardBody,
  CardFooter,
  Button,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import i18n from "../i18n";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");

  const onSubmit = async () => {
    if (loading) {
      return;
    }
    try {
      if (username) {
        setLoading(true);
        setError(false);
        setSuccess(false);
        await axios.post("/user/forgot-password", {
          username,
          locale: i18n.language,
        });
        setSuccess(true);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex bg={"blue.100"} height={"100vh"} alignItems={"center"}>
      <Container maxW="md" centerContent>
        <Card width={"100%"}>
          <CardBody>
            <FormControl isRequired>
              <FormLabel>{t("username")}</FormLabel>
              <Input
                placeholder={t("username")}
                name="username"
                onChange={({ target }) => setUsername(target.value)}
              />
            </FormControl>
            {success && (
              <Alert status="success" marginTop={4}>
                <AlertIcon />
                <AlertTitle>{t("forgottenPasswordSubmitSuccess")}</AlertTitle>
              </Alert>
            )}
            {error && (
              <Alert status="error" marginTop={4}>
                <AlertIcon />
                <AlertTitle>{t("forgottenPasswordSubmitError")}</AlertTitle>
              </Alert>
            )}
          </CardBody>

          <Divider />
          <CardFooter>
            <Flex
              justifyContent={"space-between"}
              alignItems="center"
              width={"100%"}
            >
              <Button colorScheme="blue" onClick={onSubmit} disabled={loading}>
                {loading ? <Spinner /> : t("sendEmail")}
              </Button>
            </Flex>
          </CardFooter>
        </Card>
      </Container>
    </Flex>
  );
};

export default ForgotPasswordPage;
