import React, { useState } from "react";
import { useQuery } from "react-query";

import "chartjs-adapter-moment";
import "chartjs-adapter-date-fns";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Stack,
} from "@chakra-ui/react";
import { ReportData } from "../pages/ebooks-page/types";
import axios from "axios";
import { SearchIcon } from "@chakra-ui/icons";

type ReportChartDetailProps = {
  isbn: String;
};

const ReportChartDetail: React.FC<ReportChartDetailProps> = ({ isbn }) => {
  const { t } = useTranslation();
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(1, "month").toDate()
  );

  const [dateTo, setDateTo] = useState(moment().toDate());
  const [searchDateFrom, setSearchDateFrom] = useState(
    moment().subtract(1, "month").toDate()
  );
  const [searchDateTo, setSearchDateTo] = useState(
    moment().subtract(1, "month").toDate()
  );
  const { isLoading, error, data } = useQuery<ReportData>({
    queryKey: [isbn, searchDateFrom, searchDateTo],
    queryFn: () =>
      axios
        .get(
          `/secure/report?isbn=${isbn}&dateFrom=${moment(searchDateFrom).format(
            "DD.MM.YY"
          )}&dateTo=${moment(searchDateTo).format("DD.MM.YY")}`
        )
        .then((res) => res.data),
    enabled: !!isbn,
  });

  if (!data || error || isLoading) {
    return null;
  }
  const datasets = data.retailerNamelist.map((retailer, index) => ({
    label: `${retailer}`,
    data: data.reportRowList.map((row) => row.values[index]),
  }));

  const changeDates = () => {
    setSearchDateFrom(dateFrom);
    setSearchDateTo(dateTo);
  };

  return (
    <Stack>
      <Flex justifyContent={"left"} alignItems="end" width={"100%"} gap={4}>
        <FormControl isRequired>
          <FormLabel>{t("dateFrom")}</FormLabel>
          <Input
            type="date"
            value={moment(dateFrom).format("YYYY-MM-DD")}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              setDateFrom(moment(target.value).toDate());
            }}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{t("dateTo")}</FormLabel>
          <Input
            type="date"
            value={moment(dateTo).format("YYYY-MM-DD")}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
              setDateTo(moment(target.value).toDate());
            }}
          />
        </FormControl>
        <Button colorScheme="blue" type="submit" onClick={changeDates}>
          <SearchIcon />
        </Button>
      </Flex>
      <Bar
        title={t("reportChartTitle".replace("${isbn}", isbn.toString()))}
        data={{
          labels: data.reportRowList.map((row) => new Date(row.date)),
          datasets: datasets,
        }}
        options={{
          plugins: {
            legend: {
              position: "bottom",
            },
            title: {
              display: true,
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
              type: "time",
              time: {
                displayFormats: {
                  day: "dd.MM.yyyy",
                },
              },
              min: searchDateFrom.getTime(),
              max: searchDateTo.getTime(),
              ticks: {
                maxTicksLimit: 10,
              },
            },
            y: {
              stacked: true,
              beginAtZero: true,
              ticks: {
                precision: 0,
                maxTicksLimit: 10,
              },
            },
          },
        }}
      />
    </Stack>
  );
};

export default ReportChartDetail;
