import { Td, Thead, Tr } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import Role from "./role";
import {
  EBook,
  EbooksPageTableItem,
  PageTableItem,
} from "../pages/ebooks-page/types";
import moment, { MomentInput } from "moment";
import State, { StateType } from "./state";

export type EBooksPageRowProps = {
  schema: EbooksPageTableItem[];
  ebook: EBook;
};

export type EbooksPageRowColumnProps = {
  column: EbooksPageTableItem;
  ebook: EBook;
};

export type PageRowProps<P> = {
  schema: PageTableItem<P>[];
  object: P;
};

export type PageRowColumnProps<P> = {
  column: PageTableItem<P>;
  object: P;
};

const PageRowColumn = <P,>({
  column,
  object,
}: PageRowColumnProps<P>): ReactElement => {
  const value = column.valueTransform
    ? column.valueTransform<P>(object[column.key as keyof P], object)
    : object[column.key as keyof P];
  if (column.type === "date") {
    return (
      <Td style={column.style}>
        {moment(value as MomentInput).format("DD.MM.YY")}
      </Td>
    );
  }
  if (column.type === "image") {
    return (
      <Td style={column.style}>
        <img src={`${value}`} height="60px" width={"40px"} />
      </Td>
    );
  }
  if (column.type === "state") {
    return (
      <Td style={column.style}>
        <State
          value={value as StateType}
          link={column.link ? column.link(object) : ""}
        />
      </Td>
    );
  }
  if (column.onClick) {
    return (
      <Td
        style={column.style}
        onClick={() => column.onClick && column.onClick(object)}
        cursor="pointer"
        textDecoration="underline"
      >
        {value as string | number}
      </Td>
    );
  }
  return <Td style={column.style}>{value as string | number}</Td>;
};

const PageRow = <P,>({ schema, object }: PageRowProps<P>): ReactElement => (
  <Tr>
    {schema.map((column: PageTableItem<P>) =>
      column.roles.length > 0 ? (
        <Role roles={column.roles}>
          <PageRowColumn column={column} object={object} />
        </Role>
      ) : (
        <PageRowColumn column={column} object={object} />
      )
    )}
  </Tr>
);

export default PageRow;
