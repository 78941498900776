import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";

type FileUploadModalProps = {
  isbn?: String;
  close: () => void;
  isOpen: boolean;
};

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isbn,
  close,
  isOpen,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [shortDescription, setShortDescription] = useState<string>("");
  const [longDescription, setLongDescription] = useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      if (file) {
        formData.append("file", file);
      }
      formData.append("shortDescription", shortDescription);
      formData.append("longDescription", longDescription);

      try {
        const response = await axios.post(
          `/api/files/${isbn}/upload`,
          formData
        );
        close(); // Close the modal
      } catch (err) {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => close()}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload File</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Upload File</FormLabel>
              <Input type="file" onChange={handleFileChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Short Description</FormLabel>
              <Input
                type="text"
                placeholder="Enter short description"
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Long Description</FormLabel>
              <Textarea
                placeholder="Enter long description"
                value={longDescription}
                onChange={(e) => setLongDescription(e.target.value)}
              />
            </FormControl>
            <Button colorScheme="blue" onClick={handleSubmit}>
              Submit
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FileUploadModal;
