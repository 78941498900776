import moment from "moment";
import { FC } from "react";
import { useQuery } from "react-query";
import { ReportData } from "../ebooks-page/types";
import { Bar } from "react-chartjs-2";
import { Flex, Spinner } from "@chakra-ui/react";
import axios from "axios";
import { useTranslation } from "react-i18next";

type ReportsPageChartProps = {
  filter: {
    dateTo: Date;
    dateFrom: Date;
    publisher: string;
  };
};

const ReportChartPage: FC<ReportsPageChartProps> = ({ filter }) => {
  const { t } = useTranslation();
  const { isLoading, error, data } = useQuery<ReportData>({
    queryKey: [
      "report-aggregated",
      filter.dateFrom,
      filter.dateTo,
      filter.publisher,
    ],
    queryFn: () =>
      axios
        .get(
          `/secure/reportAggregated?&dateFrom=${moment(filter.dateFrom).format(
            "DD.MM.YY"
          )}&dateTo=${moment(filter.dateTo).format("DD.MM.YY")}&publisherName=${
            filter.publisher
          }`
        )
        .then((res) => res.data),
  });

  if (isLoading) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        flex="1"
        marginTop="10px"
      >
        <Spinner />
      </Flex>
    );
  }
  if (error) {
    return <span>{t("dataLoadError")}</span>;
  }

  const datasets = data?.retailerNamelist.map((retailer, index) => ({
    label: `${retailer}`,
    data: data.reportRowList.map((row) => row.values[index]),
  }));

  return (
    <Bar
      title={"Aggregated report"}
      data={{
        labels: data?.reportRowList.map((row) => new Date(row.date)),
        datasets: datasets || [],
      }}
      options={{
        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: true,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            type: "time",
            time: {
              displayFormats: {
                day: "dd.MM.yyyy",
              },
            },
            min: filter.dateFrom.getTime(),
            max: filter.dateTo.getTime(),
            ticks: {
              maxTicksLimit: 10,
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              precision: 0,
              maxTicksLimit: 10,
            },
          },
        },
      }}
    />
  );
};

export default ReportChartPage;
