import React, { useState } from "react";
import { useQuery } from "react-query";
import { File } from "../pages/ebooks-page/types";
import {
  Button,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import axios from "axios";

type FileTableProps = {
  isbn?: String;
  addFile: () => void;
};

const FileTable: React.FC<FileTableProps> = ({ isbn, addFile }) => {
  const { t } = useTranslation();
  const [version, setVersion] = useState(0);

  const { isLoading, error, data } = useQuery<File[]>({
    queryKey: ["files", isbn, version],
    queryFn: () => axios.get(`/api/files/${isbn}`).then((res) => res.data),
    enabled: !!isbn,
  });

  const deleteFile = (id: number) => {
    if (window.confirm("Are you sure you want to delete file?")) {
      axios
        .delete(`/api/files/${id}`)
        .then(() => {
          setVersion((v) => v + 1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>{t("fileName")}</Th>
            <Th>{t("shortDescription")}</Th>
            <Th>{t("createdAt")}</Th>
            <Th>{t("createdBy")}</Th>
            <Th>{t("actions")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && (
            <Tr>
              <Td colSpan={5} alignItems="center">
                <Spinner />
              </Td>
            </Tr>
          )}
          {!!error && (
            <Tr>
              <Td colSpan={5}>Error when loading data</Td>
            </Tr>
          )}
          {data?.map((file: File) => (
            <Tr>
              <Td>{file.fileName}</Td>
              <Td>{file.shortDescription}</Td>
              <Td>{file.createdAt}</Td>
              <Td>{file.createdBy}</Td>
              <Td>
                <Stack direction="row">
                  <a href={`/api/files/${file.id}/download`} target="_blank">
                    <Button colorScheme="blue">
                      <DownloadIcon />
                    </Button>
                  </a>
                  <Button colorScheme="red" onClick={() => deleteFile(file.id)}>
                    <DeleteIcon />
                  </Button>
                </Stack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Button colorScheme="blue" onClick={() => addFile()}>
        Add file
      </Button>
    </>
  );
};

export default FileTable;
