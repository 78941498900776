import React, { useContext } from "react";
import UserContext from "../context/user-context";

type RoleProps = {
  children: JSX.Element;
  roles: string[];
};

const Role: React.FC<RoleProps> = ({ children, roles }) => {
  const user = useContext(UserContext);

  if (roles.some((role: string) => user?.roles.includes(role))) {
    return children;
  }
  return null;
};

export default Role;
