import React, { useMemo, useState } from "react";
import {
  Container,
  FormControl,
  FormLabel,
  Input,
  Card,
  CardBody,
  CardFooter,
  Button,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import i18n from "../i18n";
import { useLocation } from "react-router-dom";

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const onSubmit = async () => {
    if (loading) {
      return;
    }
    try {
      if (password && password === passwordConfirm) {
        setLoading(true);
        setError(false);
        console.log({
          password,
          passwordConfirm,
          email: query.get("email"),
          code: query.get("code"),
        });
        await axios.post("/user/reset-password", {
          password,
          email: query.get("email"),
          code: query.get("code"),
        });
        //@ts-ignore
        window.location = "/react#/login?newPassword=true";
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex bg={"blue.100"} height={"100vh"} alignItems={"center"}>
      <Container maxW="md" centerContent>
        <Card width={"100%"}>
          <CardBody>
            <FormControl isRequired>
              <FormLabel>{t("password")}</FormLabel>
              <Input
                placeholder={t("password")}
                name="password"
                type="password"
                onChange={({ target }) => setPassword(target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>{t("passwordConfirm")}</FormLabel>
              <Input
                placeholder={t("passwordConfirm")}
                name="passwordConfirm"
                type="password"
                onChange={({ target }) => setPasswordConfirm(target.value)}
              />
            </FormControl>
            {error && (
              <Alert status="error" marginTop={4}>
                <AlertIcon />
                <AlertTitle>{t("resetPasswordSubmitError")}</AlertTitle>
              </Alert>
            )}
          </CardBody>

          <Divider />
          <CardFooter>
            <Flex
              justifyContent={"space-between"}
              alignItems="center"
              width={"100%"}
            >
              <Button colorScheme="blue" onClick={onSubmit} disabled={loading}>
                {loading ? <Spinner /> : t("changePassword")}
              </Button>
            </Flex>
          </CardFooter>
        </Card>
      </Container>
    </Flex>
  );
};

export default ResetPasswordPage;
