import React from "react";
import { Flex, Image } from "@chakra-ui/react";
import EnFlag from "../assets/en.png";
import DeFlag from "../assets/de.png";
import i18n from "../i18n";

const LanguageSelector = () => {
  return (
    <Flex gap={0.5}>
      <Image
        src={EnFlag}
        width="32px"
        height="24px"
        borderRadius={5}
        border="2px"
        borderColor={i18n.language === "en" ? "blue.500" : "blue.50"}
        onClick={() => i18n.changeLanguage("en")}
        cursor="pointer"
      />
      <Image
        src={DeFlag}
        width="32px"
        height="24px"
        borderRadius={5}
        border="2px"
        borderColor={i18n.language === "de" ? "blue.500" : "blue.50"}
        onClick={() => i18n.changeLanguage("de")}
        cursor="pointer"
      />
    </Flex>
  );
};

export default LanguageSelector;
