import React, { useState, useMemo } from "react";
import {
  Container,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  Divider,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Flex bg={"blue.100"} height={"100vh"} alignItems={"center"}>
      <Container maxW="md" centerContent>
        <Card width={"100%"}>
          <CardBody>
            <Alert status="error" marginTop={4}>
              <AlertIcon />
              <AlertTitle>{t("notFound")}</AlertTitle>
            </Alert>
          </CardBody>
          <Divider />
          <CardFooter>
            <Flex
              justifyContent={"space-between"}
              alignItems="center"
              width={"100%"}
            >
              <Link href="/react#/">{t("backToHomePage")}</Link>
            </Flex>
          </CardFooter>
        </Card>
      </Container>
    </Flex>
  );
};

export default NotFoundPage;
