import React from "react";
import { type Rating } from "../pages/ebooks-page/types";
import { useTranslation } from "react-i18next";
import {
  Card,
  Heading,
  CardBody,
  Stack,
  StackDivider,
  Box,
  CircularProgress,
  CircularProgressLabel,
  HStack,
} from "@chakra-ui/react";
import moment from "moment";
import { AtSignIcon, CalendarIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { getRatingColor } from "../utils";

type RatingCardProps = {
  rating: Rating;
};

const RatingCard: React.FC<RatingCardProps> = ({ rating }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardBody>
        <Heading
          size="md"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <span>{rating.header}</span>
          <CircularProgress
            value={(rating.rating / rating.ratingMax) * 100}
            color={getRatingColor((rating.rating / rating.ratingMax) * 100)}
          >
            <CircularProgressLabel>
              {rating.rating}/{rating.ratingMax}
            </CircularProgressLabel>
          </CircularProgress>
        </Heading>
        <Stack divider={<StackDivider />} spacing="1">
          <Box>
            <HStack spacing="24px">
              <Box display="flex" gap={2} alignItems="center">
                <CalendarIcon />
                {moment(rating.date).format("DD.MM.YYYY")}
              </Box>
              <Box display="flex" gap={2} alignItems="center">
                <AtSignIcon />
                {rating.userName}
              </Box>
              <Box display="flex" gap={2} alignItems="center">
                <ExternalLinkIcon />
                {rating.salesChannel}
              </Box>
            </HStack>
          </Box>
          <Box>
            <span
              dangerouslySetInnerHTML={{ __html: rating.text.toString() }}
            ></span>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default RatingCard;
