import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Spinner,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Rating } from "../pages/ebooks-page/types";
import RatingCard from "./rating-card";
import { useQuery } from "react-query";
import axios from "axios";

type RatingsListDrawerProps = {
  isbn?: String;
  isOpen: boolean;
  close: () => void;
};

const RatingsListDrawer: React.FC<RatingsListDrawerProps> = ({
  isbn,
  close,
  isOpen,
}) => {
  const { t } = useTranslation();

  const { isLoading, error, data } = useQuery<Rating[]>({
    queryKey: ["rating", isbn],
    queryFn: () =>
      axios.get(`/api/rating/ebook/${isbn}`).then((res) => res.data),
    enabled: !!isbn,
  });

  return (
    <Drawer onClose={close} isOpen={isOpen} size={"xl"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          {t("ratingsDrawerTitle").replace("${isbn}", isbn)}
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <Stack divider={<StackDivider />} spacing="1">
            {isLoading && <Spinner />}
            {data &&
              data.map((rating: Rating) => <RatingCard rating={rating} />)}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default RatingsListDrawer;
