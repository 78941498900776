import { createContext } from "react";

export type User = {
  firstName: string;
  lastName: string;
  roles: string[];
  permissions: string[];
};

export default createContext<User | undefined>(undefined);
