import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, AddIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import LanguageSelector from "./language-selector";

import Logo from "../assets/logo.jpg";

type NavLinkProps = {
  children: React.ReactNode;
  path: string;
};

const Links = [
  { label: "ebooks", path: "/ebooks" },
  { label: "reports", path: "/reports" },
  // { label: "ratings", path: "/ratings" },
];

const NavLink = (props: NavLinkProps) => {
  const { children, path } = props;
  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      href={"#"}
    >
      <Link to={path}>{children}</Link>
    </Box>
  );
};

export const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const location = useLocation();

  const goToOldVersion = () => {
    console.log("weeee", window.location.href);
    if (window.location.href.includes("ebooks")) {
      // @ts-ignore
      return (window.location.href = "/secure/ebookListPage");
    }
    if (window.location.href.includes("reports")) {
      // @ts-ignore
      return (window.location.href = "/secure/userReportPage");
    }
    // if (window.location.href.includes("ratings")) {
    //   // @ts-ignore
    //   return (window.location.href = "/secure/ratingList");
    // }
  };

  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          size={"md"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={"center"}>
          <Box>
            <Image src={Logo} alt="Logo" />
          </Box>
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            {Links.map((link) => (
              <NavLink key={link.path} path={link.path}>
                <span
                  style={{
                    fontWeight: location.pathname.includes(link.path)
                      ? 700
                      : undefined,
                  }}
                >
                  {t(link.label)}
                </span>
              </NavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={"center"} gap={4}>
          <LanguageSelector />
          <Button
            variant="outline"
            colorScheme={"teal"}
            size={"sm"}
            onClick={goToOldVersion}
          >
            Old Version
          </Button>
          <a href="/perform_logout">
            <Button variant={"solid"} colorScheme={"teal"} size={"sm"}>
              {t("logout")}
            </Button>
          </a>
        </Flex>
      </Flex>
      {isOpen ? (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {Links.map((link) => (
              <NavLink key={link.path} path={link.path}>
                {t(link.label)}
              </NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default Header;
