import {
  Stack,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
} from "@chakra-ui/react";
import { t } from "i18next";
import moment from "moment";
import Header from "../../components/header";
import { useState } from "react";
import { useQuery } from "react-query";
import ReportsPageTable from "./reports-page-table";
import ReportsPageChart from "./reports-page-chart";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import axios from "axios";

const ReportsPage = () => {
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(1, "month").toDate()
  );
  const [dateTo, setDateTo] = useState(moment().toDate());
  const [publisher, setPublisher] = useState("");
  const [filter, setFilter] = useState({
    dateTo: moment().toDate(),
    dateFrom: moment().subtract(1, "month").toDate(),
    publisher: "",
  });

  const {
    isLoading: isLoadingRetailers,
    error: errorLoadingRetailers,
    data: retailers,
  } = useQuery<string[]>({
    queryKey: ["publishers"],
    queryFn: () => axios.get(`/api/user/publishers`).then((res) => res.data),
  });

  const changeFilter = () => {
    setFilter({
      dateTo: dateTo,
      dateFrom: dateFrom,
      publisher: publisher,
    });
  };

  const clearFilter = () => {
    setDateTo(moment().toDate());
    setDateFrom(moment().subtract(1, "month").toDate());
    setPublisher("");
    changeFilter();
  };

  return (
    <div style={{ height: "100vh", overflowY: "scroll" }} id="scrollbar-target">
      <Header />
      <Stack>
        <Flex
          justifyContent={"left"}
          alignItems="end"
          width={"100%"}
          gap={4}
          padding="0 10px"
        >
          <FormControl isRequired>
            <FormLabel>{t("dateFrom")}</FormLabel>
            <Input
              type="date"
              value={moment(dateFrom).format("YYYY-MM-DD")}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                setDateFrom(moment(target.value).toDate());
              }}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>{t("dateTo")}</FormLabel>
            <Input
              type="date"
              value={moment(dateTo).format("YYYY-MM-DD")}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                setDateTo(moment(target.value).toDate());
              }}
            />
          </FormControl>
          {!isLoadingRetailers && !errorLoadingRetailers && (
            <Select onChange={({ target }) => setPublisher(target.value)}>
              <option value="">{t("all")}</option>
              {retailers?.map((retailer) => (
                <option value={retailer}>{retailer}</option>
              ))}
            </Select>
          )}
          <Button
            colorScheme="blue"
            type="submit"
            onClick={clearFilter}
            variant="outline"
          >
            <CloseIcon />
          </Button>
          <Button colorScheme="blue" type="submit" onClick={changeFilter}>
            <SearchIcon />
          </Button>
        </Flex>
        <Tabs>
          <TabList>
            <Tab>{t("table")}</Tab>
            <Tab>{t("chart")}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel style={{ padding: 0 }}>
              <ReportsPageTable filter={filter} />
            </TabPanel>
            <TabPanel style={{ padding: 0 }}>
              <ReportsPageChart filter={filter} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </div>
  );
};

export default ReportsPage;
