import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import FileTable from "./file-table";

type FileDrawerProps = {
  isbn?: String;
  isOpen: boolean;
  close: () => void;
  addFile: () => void;
};

const FileDrawer: React.FC<FileDrawerProps> = ({
  isbn,
  close,
  isOpen,
  addFile,
}) => {
  const { t } = useTranslation();
  return (
    <Drawer onClose={close} isOpen={isOpen} size={"xl"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {t("fileDrawerTitle").replace("${isbn}", `${isbn}`)}
        </DrawerHeader>
        <DrawerBody>
          <FileTable isbn={isbn} addFile={addFile} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default FileDrawer;
