import { Table, Tbody, Tr, Td, Spinner } from "@chakra-ui/react";
import { error } from "console";
import { t } from "i18next";
import { FC } from "react";
import PageHeader from "../../components/page-header";
import PageRow from "../../components/page-row";
import { PageTableItem } from "../ebooks-page/types";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";
import axios from "axios";

type ReportsPageTableProps = {
  filter: {
    dateTo: Date;
    dateFrom: Date;
    publisher: string;
  };
};

type Report = {
  isbn: string;
  title: string;
  amount: number;
  publisher: string;
};

const getReportTableSchema = (
  t: (key: string) => string
): PageTableItem<Report>[] => [
  {
    label: t("isbn"),
    key: "isbn",
    roles: [],
    sortable: true,
  },
  {
    label: t("title"),
    key: "title",
    roles: [],
    sortable: true,
  },
  {
    label: t("amount"),
    key: "amount",
    roles: [],
    sortable: true,
  },
  {
    label: t("publisher"),
    key: "publisher",
    roles: [],
    sortable: true,
  },
];

const ReportsPageTable: FC<ReportsPageTableProps> = ({ filter }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const handleSort = (fieldName: string) => {
    let direction = "DESC";

    if (
      fieldName === searchParams.get("fieldName") &&
      searchParams.get("direction") === "DESC"
    ) {
      direction = "ASC";
    }
    setSearchParams(new URLSearchParams({ fieldName, direction }));
  };

  const { isLoading, error, data } = useQuery<Report[]>({
    queryKey: [
      "retailers",
      filter.dateFrom,
      filter.dateTo,
      filter.publisher,
      searchParams.get("fieldName"),
      searchParams.get("direction"),
    ],
    queryFn: () =>
      axios
        .get(
          `/api/report/list?dateFrom=${moment(filter.dateFrom).format(
            "DD.MM.YY"
          )}&dateTo=${moment(filter.dateTo).format("DD.MM.YY")}&publisher=${
            filter.publisher
          }&fieldName=${searchParams.get("fieldName") || ""}&direction=${
            searchParams.get("direction") || ""
          }`
        )
        .then((res) => res.data),
  });

  return (
    <Table size="sm">
      <PageHeader
        schema={getReportTableSchema(t)}
        handleSort={handleSort}
        sort={{
          fieldName: searchParams.get("fieldName"),
          direction: searchParams.get("direction"),
        }}
      />
      <Tbody>
        {isLoading && (
          <Tr>
            <Td colSpan={17} textAlign="center">
              <Spinner />
            </Td>
          </Tr>
        )}
        {!!error && (
          <Tr>
            <Td colSpan={4}>{t("dataLoadError")}</Td>
          </Tr>
        )}
        {data?.map((report: Report) => (
          <PageRow<Report> schema={getReportTableSchema(t)} object={report} />
        ))}
      </Tbody>
    </Table>
  );
};

export default ReportsPageTable;
