import React, { useEffect } from "react";

type RedirectProps = {
  path: string;
};

const Redirect: React.FC<RedirectProps> = ({ path }) => {
  useEffect(() => {
    // @ts-ignore
    window.location = path;
  }, []);
  return <div />;
};

export default Redirect;
