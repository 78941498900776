import React, { useState, useMemo } from "react";
import {
  Container,
  FormControl,
  FormLabel,
  Input,
  Card,
  CardBody,
  CardFooter,
  Button,
  Flex,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  Divider,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoginPage = () => {
  const { t } = useTranslation();

  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <Flex bg={"blue.100"} height={"100vh"} alignItems={"center"}>
      <Container maxW="md" centerContent>
        <Card width={"100%"}>
          <form method="post" action="/perform_login">
            <CardBody>
              <FormControl isRequired>
                <FormLabel>{t("username")}</FormLabel>
                <Input placeholder={t("username")} name="username" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>{t("password")}</FormLabel>
                <Input
                  placeholder={t("password")}
                  name="password"
                  type="password"
                />
              </FormControl>
              {query.get("error") === "true" && (
                <Alert status="error" marginTop={4}>
                  <AlertIcon />
                  <AlertTitle>{t("loginError")}</AlertTitle>
                </Alert>
              )}
              {query.get("newPassword") === "true" && (
                <Alert status="success" marginTop={4}>
                  <AlertIcon />
                  <AlertTitle>{t("newPasswordSuccess")}</AlertTitle>
                </Alert>
              )}
            </CardBody>

            <Divider />
            <CardFooter>
              <Flex
                justifyContent={"space-between"}
                alignItems="center"
                width={"100%"}
              >
                <Button colorScheme="blue" type="submit">
                  {t("login")}
                </Button>
                <Link href="/react#/forgot-password">
                  {t("forgotPasswordButton")}
                </Link>
              </Flex>
            </CardFooter>
          </form>
        </Card>
      </Container>
    </Flex>
  );
};

export default LoginPage;
