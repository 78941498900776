import { Th, Thead, Tr } from "@chakra-ui/react";
import { ReactElement } from "react";
import Role from "./role";
import { PageTableItem, Sort } from "../pages/ebooks-page/types";
import SortableIcon from "./sortable-icon";

export type PageHeaderProps<T> = {
  schema: PageTableItem<T>[];
  sort: Sort;
  handleSort: (field: string) => void;
};

const PageHeader = <T,>({
  schema,
  sort,
  handleSort,
}: PageHeaderProps<T>): ReactElement => {
  return (
    <Thead>
      <Tr>
        {schema.map((column) =>
          column.roles.length > 0 ? (
            <Role roles={column.roles}>
              <Th
                style={column.style}
                onClick={() => column.sortable && handleSort(column.key)}
                cursor={column.sortable ? "pointer" : undefined}
              >
                {column.label}
                <SortableIcon sort={sort} column={column} />
              </Th>
            </Role>
          ) : (
            <Th
              style={column.style}
              onClick={() => column.sortable && handleSort(column.key)}
              cursor={column.sortable ? "pointer" : undefined}
            >
              {column.label}
              <SortableIcon sort={sort} column={column} />
            </Th>
          )
        )}
      </Tr>
    </Thead>
  );
};

export default PageHeader;
