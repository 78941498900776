import { EBook } from "./types";

export const getCoverLink = (ebook: EBook) => `/api/ebook/${ebook.isbn}?type=COVER`;
export const getEpubLink = (ebook: EBook) => `/api/ebook/${ebook.isbn}?type=EPUB`;
export const getThaliaLink = (ebook: EBook) =>
  `http://www.thalia.de/shop/home/artikeldetails/EAN${ebook.isbn}`;
export const getWeltbildLink = (ebook: EBook) =>
  `https://www.weltbild.de/suche/${ebook.isbn}`;
export const getAmazonLink = (ebook: EBook) =>
  `http://amazon.de/dp/${ebook.asin}`;
export const getAppleLink = (ebook: EBook) =>
  `https://itunes.apple.com/de/book/id${ebook.appleId}`;
export const getKoboLink = (ebook: EBook) =>
  `https://store.kobobooks.com/search?Query=${ebook.isbn}`;
export const getGoogleLink = (ebook: EBook) =>
  `https://play.google.com/store/search?q=${ebook.isbn}&c=books&hl=de`;
export const getBarnesLink = (ebook: EBook) =>
  `https://www.barnesandnoble.com/w?ean=${ebook.isbn}`;
