import React, { ReactElement } from "react";
import {
  EbooksPageTableItem,
  PageTableItem,
  Sort,
} from "../pages/ebooks-page/types";
import { ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon } from "@chakra-ui/icons";

type SortableIconProps<T> = {
  column: PageTableItem<T>;
  sort: Sort;
};

const SortableIcon = <T,>({
  column,
  sort,
}: SortableIconProps<T>): ReactElement => {
  if (!column.sortable) {
    return <></>;
  }
  if (sort.fieldName === column.key && sort.direction === "ASC") {
    return <ArrowUpIcon />;
  }
  if (sort.fieldName === column.key && sort.direction === "DESC") {
    return <ArrowDownIcon />;
  }
  return <ArrowUpDownIcon />;
};

export default SortableIcon;
