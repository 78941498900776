export const getRatingColor = (pct: number) => {
    if (pct >= 80) {
      return "green.300";
    }
    if (pct >= 60) {
      return "yellow.300";
    }
    if (pct >= 40) {
      return "orange.300";
    }
    if (pct >= 20) {
      return "red.300";
    }
    return "red.600";
  };