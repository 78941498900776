import React, { useEffect, useState, useMemo } from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";

import LoginPage from "./pages/login-page";
import EBooksPage from "./pages/ebooks-page/ebooks-page";
import UserContext, { User } from "./context/user-context";
// import RatingsPage from "./pages/ratings-page/ratings-page";
import ReportsPage from "./pages/reports-page/reports-page";
import Redirect from "./components/redirect";
import NotFoundPage from "./pages/not-found-page";
import axios, { AxiosError } from "axios";
import ChangePasswordPage from "./pages/change-password-page";
import ForgotPasswordPage from "./pages/forgot-password-page";
import ResetPasswordPage from "./pages/reset-password-page";

const App = () => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const loadUser = async () => {
      axios("/api/user")
        .then((res) => setUser(res.data))
        .catch((err: AxiosError<{ status: number; message: string }>) => {
          console.log(err?.response?.data);
          if (
            err?.response?.data?.status === 403 &&
            err?.response?.data?.message.includes("CHANGE_PASSWORD")
          ) {
            //@ts-ignore
            window.location = `/react#/change-password?username=${err?.response?.data?.message.replace(
              "CHANGE_PASSWORD#",
              ""
            )}`;
          } else if (
            !window.location ||
            (!window.location.href.includes("/react#/login") &&
              !window.location.href.includes("/react#/forgot-password") &&
              !window.location.href.includes("/react#/reset-password"))
          ) {
            //@ts-ignore
            window.location = `/react#/login`;
          }
        });
    };
    loadUser();
  }, []);

  const router = createHashRouter([
    {
      path: "/",
      element: <Redirect path="/react#/ebooks" />,
    },
    {
      path: "/ebooks",
      element: <EBooksPage />,
    },
    {
      path: "/login/:error?",
      element: <LoginPage />,
    },
    {
      path: "/change-password",
      element: <ChangePasswordPage />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPasswordPage />,
    },
    {
      path: "/reset-password",
      element: <ResetPasswordPage />,
    },
    {
      path: "/reports",
      element: <ReportsPage />,
    },
    // {
    //   path: "/ratings",
    //   element: <RatingsPage />,
    // },
    {
      path: "/*",
      element: <NotFoundPage />,
    },
  ]);

  return (
    <UserContext.Provider value={user}>
      <RouterProvider router={router} />
    </UserContext.Provider>
  );
};

export default App;
